.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



input {
  margin-top: 36px;
  color: #f2a365;
  border: 3px solid #38cfb1;
  padding: 10px;
  width: 300px;
  background-color: #5b5656;
  border-radius: 10px;
}

button {
  
  top: 10px;
  right: 10px;
  font-size: 15px;
  padding: 5px 20px;
  color: #ececec;
  border: 3px solid #4D4646;
  border-radius: 20px;
  width: auto;
  background-color: #38cfb1;
  z-index: 100;
  font-weight: bold;
  margin: 36px;

  
  
}

button:hover {
  
    box-shadow: inset 0 0 10px #000000;
    cursor: pointer;
    color: #f2a365;
}

h1 {

  padding: 50px;
  margin: 0 auto;
  color: white;
  font-size: 3rem;
  -webkit-text-stroke: 2px #38cfb1;

}




